@charset "UTF-8";
@import "assets/fonts/stylesheet.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "~bootstrap/dist/css/bootstrap.min.css";
body {
  font-family: "Roboto";
  overflow-x: hidden !important;
  color: #000 !important;
  white-space: initial;
}

.container {
  max-width: 1330px;
}

h2 {
  font-size: 35px !important;
  line-height: 40px !important;
  color: #fff !important;
  font-weight: bold !important;
}
h2.blk {
  color: #000 !important;
}
@media (max-width: 767px) {
  h2 {
    font-size: 29px !important;
  }
}

h3 {
  font-size: 26px !important;
  line-height: 30px !important;
  color: #fff !important;
  font-weight: bold !important;
}
h3.blk {
  color: #000 !important;
}
@media (max-width: 767px) {
  h3 {
    font-size: 27px !important;
  }
}

h3.tf {
  font-size: 24px !important;
  line-height: 30px !important;
  color: #fff !important;
  font-weight: bold !important;
}
h3.tf.blk {
  color: #000 !important;
}
@media (max-width: 767px) {
  h3.tf {
    font-size: 25px !important;
  }
}

h4 {
  font-size: 22px !important;
  font-weight: bold !important;
  color: #fff !important;
  line-height: 30px !important;
}
h4.blk {
  color: #000 !important;
}
@media (max-width: 767px) {
  h4 {
    font-size: 21px !important;
  }
}

h5 {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #fff !important;
  line-height: 30px !important;
}
h5.blk {
  color: #000 !important;
}
@media (max-width: 767px) {
  h5 {
    font-size: 19px !important;
  }
}

h6 {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #fff !important;
  line-height: 30px !important;
}
h6.blk {
  color: #000 !important;
}
@media (max-width: 767px) {
  h6 {
    font-size: 17px !important;
  }
}

.Usermsg {
  color: #b34bc9 !important;
  margin: 0px auto;
  text-align: center;
  text-shadow: 0px 0px 3px #ddd;
  font-size: 35px !important;
}
@media (max-width: 767px) {
  .Usermsg {
    font-size: 29px !important;
  }
}

p {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
}
p.blk {
  color: #000 !important;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.bgchange {
  background: #f0f1f2;
}

.main {
  display: flex;
  width: 100%;
}

.pad0 {
  padding: 0;
}

p {
  font-size: 16px;
  color: #000;
}

.stick3 {
  position: sticky;
  top: 0px;
  z-index: 100;
}
.stick3 .top-panel {
  height: 80px;
  background: #eef2f5;
  width: 100%;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
}
.stick3 .header-top .d-logo {
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
.stick3 .header-top .d-logo img {
  width: 50%;
}

.signUpbar {
  width: 100%;
  background: #ce2c4f;
  height: 50px;
  display: flex;
  align-items: center;
}
.signUpbar .bred-Headings h4 {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  font-family: "Montserrat";
  font-weight: bold;
  font-style: normal;
}
.signUpbar .bred-Headings p {
  margin: 0;
  color: #fff;
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
}
.signUpbar .bred-Headings p a {
  color: #6ee2e2;
}

.signup {
  background: #f0f1f2;
}
.signup .accord {
  margin-top: 15px;
  width: 100%;
  flex: 1;
}
.signup .signupmobile {
  display: none;
}
.signup .box {
  background: #fff;
  margin-bottom: 25px !important;
  border-radius: 3px;
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 25px;
}
.signup .box .level-box {
  display: none;
  display: flex;
  padding: 0px;
  background: #fff;
  flex-direction: row;
}
.signup .box .padingl0 {
  padding-left: 0px;
}
.signup .headerbtn {
  min-height: auto;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 30px;
  padding: 0px 0px 20px 0px;
  min-height: auto;
}
.signup h2.header {
  color: #222222;
  text-align: left;
  display: flex;
  position: relative;
  margin: 0px;
  width: 100%;
  font-size: 20px;
  align-items: flex-start;
  flex-direction: column;
  font-family: "Montserrat";
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
}
.signup h2.header span.padlock {
  font-size: 12px;
  position: relative;
  color: #282d3d;
  display: block;
  font-weight: 400;
  padding-top: 10px;
  padding-left: 20px;
  line-height: 20px;
}
.signup h2.header span.padlock:before {
  content: "";
  background: url(../src/assets/img/signup/padlock.svg) no-repeat;
  position: absolute;
  width: 18px;
  height: 15px;
  z-index: 12;
  left: 0px;
}
.signup .boxnewjs .headerbtn {
  border: 0px;
  padding: 0px;
}
.signup form {
  width: 100%;
}
.signup label {
  width: 100%;
  display: block;
  font-size: 0.813rem;
  color: #595959;
  margin: 0px;
  font-weight: 600;
  flex: 1;
  text-transform: uppercase;
  color: #282d3d;
}
.signup input,
.signup select {
  width: 100%;
  border: none;
  height: 40px;
  border-bottom: 1px solid #ddd;
  background: transparent;
  outline: none;
  box-shadow: none;
  font-size: 14px;
  color: #282d3d;
  margin-bottom: 10px;
}
.signup input::placeholder,
.signup select::placeholder {
  color: #282d3d;
}
.signup input:-ms-input-placeholder,
.signup select:-ms-input-placeholder {
  color: #282d3d;
}
.signup input::-ms-input-placeholder,
.signup select::-ms-input-placeholder {
  color: #282d3d;
}
.signup .monthly {
  border-radius: 5px;
  border: 1px solid #282d3d;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  height: 60px;
}
.signup .monthly .amount {
  right: 12px;
  font-weight: 700;
  top: 12px;
  line-height: 37px;
}
.signup .monthly.active {
  background: #282d3d;
}
.signup .monthly.active .container-box {
  color: #fff;
}
.signup .monthly.active .container-box span {
  color: #fff;
}
.signup .container-box {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: capitalize;
  font-family: "Montserrat";
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
  /*radio button click small circal*/
  /* When the radio button is checked, add a blue background */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */
}
.signup .container-box span {
  font-family: "Open Sans";
  color: #282d3d;
  display: block;
  font-size: 12px;
  color: #969696;
  font-weight: 400;
  display: block;
}
.signup .container-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: auto;
}
.signup .container-box:hover input ~ .checkmark-circle {
  background-color: #fff;
}
.signup .container-box input:checked ~ .checkmark-circle {
  background-color: #ffffff;
}
.signup .container-box .checkmark-circle:after {
  content: "";
  position: absolute;
  display: none;
}
.signup .container-box input:checked ~ .checkmark-circle:after {
  display: block;
}
.signup .container-box .checkmark-circle:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #282d3d;
}
.signup .checkmark-circle {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #9e9e9e;
  box-shadow: inset 0 0 3px #626262;
  -moz-box-shadow: inset 0 0 3px #626262;
  -webkit-box-shadow: inset 0 0 3px #626262;
}
.signup label + span {
  position: absolute;
  font-size: 13px;
  font-weight: 400;
  color: #333333;
  right: 0px;
  top: 30px;
}
.signup .form-group.success input {
  border-bottom: 2px solid #7ad267;
}
.signup .form-group.success:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 24px;
  border-radius: 50%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7ad267;
  border: 1px solid #7ad267;
}
.signup .form-group.select-grp {
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
}
.signup .form-group.select-grp:focus {
  outline: none;
}
.signup .form-group.select-grp .btn-light:not(:disabled):not(.disabled).active,
.signup .form-group.select-grp .btn-light:not(:disabled):not(.disabled):active,
.signup .form-group.select-grp .show > .btn-light.dropdown-toggle {
  border-bottom: 0px !important;
}
.signup .form-group.select-grp .dropdown-menu.show {
  padding: 0px;
}
.signup .form-group.select-grp .selectpicker + button {
  width: 100%;
  border: none;
  height: 40px;
  border-bottom: 1px solid #ddd;
  border-bottom: 0px;
  background: transparent;
  outline: none;
  box-shadow: none;
  margin-bottom: 30px;
  font-size: 14px;
  color: #959595;
  border-radius: 0px;
  padding: 0px;
  line-height: 40px;
  color: #282d3d;
}
.signup .form-group.select-grp .selectpicker + button:hover {
  background: transparent;
}
.signup .form-group.select-grp .selectpicker + button:focus {
  background: transparent;
  border: 0px;
}
.signup .form-group.select-grp .btn-light:not(:disabled):not(.disabled).active,
.signup .form-group.select-grp .btn-light:not(:disabled):not(.disabled):active,
.signup .form-group.select-grp .show > .btn-light.dropdown-toggle {
  background: transparent;
  border: none;
  border-bottom: 1px solid #ddd;
}
.signup .form-group.select-grp .bootstrap-select .dropdown-toggle:focus,
.signup .form-group.select-grp .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none;
}
.signup .form-group.select-grp .bootstrap-select .dropdown-toggle:focus,
.signup .form-group.select-grp .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
  box-shadow: 0px;
}
.signup .form-group.select-grp .btn-light.focus,
.signup .form-group.select-grp .btn-light:focus {
  box-shadow: none !important;
}
.signup .form-group.select-grp .gh {
  content: "›";
}
.signup .form-group.select-grp .dropup .dropdown-toggle::after {
  content: "›";
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
  border: 0px;
}
.signup .form-group.select-grp .dropdown-toggle::after {
  content: "›";
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border: 0px;
  font-size: 25px;
  transform: rotate(90deg);
}
.signup .form-group.select-grp .bootstrap-select .dropdown-menu li {
  height: 40px;
}
.signup .form-group.select-grp .bootstrap-select .dropdown-menu li a {
  height: 40px;
}
.signup .form-group.select-grp .bootstrap-select .dropdown-menu li:hover {
  background-color: #eaeaea;
  border: none;
  outline: none;
  color: #333333;
}
.signup .form-group.select-grp .dropdown-item.active,
.signup .form-group.select-grp .dropdown-item:active {
  background-color: #eaeaea;
  border: none;
  outline: none;
  color: #333333;
}
.signup .bootstrap-select select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
  z-index: 0 !important;
}
.signup .select-grp .dropdown-menu.show {
  margin-left: 0px;
  float: left;
  max-height: 120px !important;
  min-height: 120px !important;
  width: 6px;
  background: #ffffff;
  overflow-y: unset !important;
  margin-bottom: 25px;
  position: relative;
  z-index: 100000;
}
.signup .select-grp .dropdown-menu.show > .inner.show {
  overflow-y: unset !important;
}
.signup .select-grp .dropdown-menu.show::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  border-radius: 10px;
  background-color: #f5f5f5;
}
.signup .select-grp .dropdown-menu.show::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
.signup .select-grp .dropdown-menu.show::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: none;
  background-color: #c3c3c3;
}
.signup .show {
  display: block;
}
.signup .mobbtn {
  display: none !important;
}
.signup button.signup {
  color: #fff;
  font-family: "Open Sans";
}
.signup .rightInfo h5 {
  font-size: 15px;
  border-left: 3px solid #ce2c4f;
  padding-left: 8px;
  font-weight: 600;
  color: #333333;
}
.signup .rightInfo p {
  margin-bottom: 15px;
}
.signup .rightInfo .personalDetailsGenInfo {
  text-align: left;
  font-size: 13px;
  color: #333333;
  line-height: 2.188;
  margin-bottom: 25px;
  font-weight: 400;
}
.signup .rightInfo .personalDetailsGenInfo ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  margin-bottom: 25px;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.signup .rightInfo .personalDetailsGenInfo ul li {
  font-size: 13px;
  font-weight: 400;
  padding-left: 15px;
  color: #555555;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  line-height: 20px;
}
.signup .rightInfo .personalDetailsGenInfo ul li:before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ce2c4f;
  position: absolute;
  left: 0px;
  top: 7px;
}
.signup P.heading {
  text-transform: uppercase;
  font-size: 12px;
  color: #ce2c4f;
  margin-bottom: 15px;
}
.signup .fa-credit-card {
  position: absolute;
  right: 15px;
  top: 35px;
  color: #b1b4ba;
}
.signup .stkpanel {
  margin-right: 0px;
  margin-left: 30px;
  flex-direction: column;
  padding: 15px;
  max-width: 350px;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 286px;
  width: 100%;
  margin-top: 15px;
  background: #fff;
  height: min-content;
  top: 130px;
  position: -webkit-sticky;
  position: sticky;
}
.signup .stkpanel .sub-total h5 {
  font-weight: 700;
  font-size: 15px;
  width: 100%;
  font-family: "Montserrat";
  font-weight: bold;
  font-style: normal;
  color: #282d3d;
}
.signup .stkpanel .sub-total .first-Row {
  flex: 1;
}
.signup .stkpanel .sub-total .lastAmt {
  font-size: 13px;
  font-weight: 700;
  align-items: center;
  display: contents;
}
.signup .stkpanel .sub-total .first-Row p {
  margin: 0;
  color: #282d3d;
}
.signup .stkpanel .sub-total .brd-Top {
  border-top: 1px solid #b7b7b7;
  margin-top: 10px;
}
.signup .stkpanel .sub-total .brd-Top .lastAmt {
  font-size: 13px;
  font-family: "Montserrat";
  font-weight: bold;
  font-style: normal;
  color: #282d3d;
  align-items: center;
  display: contents;
}
.signup .stkpanel .sub-total .brd-Top p {
  font-weight: 700;
  font-family: "Montserrat";
  font-weight: bold;
  font-style: normal;
  color: #282d3d;
}
.signup .stkpanel .mobvoucher {
  display: flex;
  align-items: flex-end;
}
.signup .stkpanel .mobvoucher button {
  height: 40px;
  font-size: 13px;
}
.signup .stkpanel .mobvoucher .fa {
  margin-left: 3px;
  font-size: 16px;
}
.signup .stkpanel button.certification {
  padding: 0 16px;
  outline: none;
}
.signup .stkpanel .headerbtn {
  display: none;
}
.signup .email {
  display: none;
}
.signup .email h3 {
  font-style: italic;
  font-family: "Montserrat";
  font-weight: bold;
  font-style: italic;
  color: #333333;
}
.signup .email h4 {
  font-family: "Montserrat";
  font-weight: bold;
}
.signup .email ul.circal {
  padding: 0px;
  margin: 0px;
  list-style: none;
  margin-bottom: 25px;
  max-width: 434px;
}
.signup .email ul.circal li {
  font-size: 13px;
  font-weight: 400;
  padding-left: 15px;
  color: #555555;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: flex-start;
}
.signup .email ul.circal li:before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ce2c4f;
  position: absolute;
  left: 0px;
  top: 6px;
}
.signup .pass {
  display: none;
}
.signup .pass .pure-form .form-group {
  max-width: 76%;
  position: relative;
}
.signup .pass .pure-form .form-group input {
  margin-bottom: 10px;
}
.signup .pass .pure-form .form-group.strong:after {
  content: "Strong";
  position: absolute;
  left: 100%;
  width: 120px;
  height: 35px;
  background: #64b584;
  text-align: center;
  line-height: 34px;
  color: #fff;
}
.signup .pass .pure-form .form-group.strong:before {
  content: "";
  position: absolute;
  left: calc(100% - 10px);
  width: 0px;
  height: 0px;
  top: 47%;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 12px solid #64b584;
}
.signup .pass .pure-form .form-group.weak:after {
  content: "Strong";
  position: absolute;
  left: 100%;
  width: 120px;
  height: 35px;
  background: #f1be48;
  text-align: center;
  line-height: 34px;
  color: #fff;
}
.signup .pass .pure-form .form-group.weak:before {
  content: "";
  position: absolute;
  left: calc(100% - 10px);
  width: 0px;
  height: 0px;
  top: 47%;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 12px solid #f1be48;
}
.signup .pass .pure-form .form-group input {
  max-width: 100%;
}
.signup .pass .pure-form .form-group button {
  border: 0px;
  position: absolute;
  left: 87%;
  z-index: 100;
  top: 33px;
  background: transparent;
  font-size: 12px;
  color: #ce2c4f;
}
.signup .pass .pure-form + ul.circal {
  padding: 0px;
  margin: 0px;
  list-style: none;
  margin-bottom: 25px;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.signup .pass .pure-form + ul.circal li {
  font-size: 13px;
  font-weight: 400;
  padding-left: 15px;
  color: #555555;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 38%;
}
.signup .pass .pure-form + ul.circal li:before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ce2c4f;
  position: absolute;
  left: 0px;
  top: 6px;
}
.signup button.certification {
  width: 100%;
  max-width: 100%;
  margin-bottom: 40px;
  font-size: 16px;
  height: 50px;
  background: #282d3d;
  border-radius: 20px 20px 0px 20px;
  outline: none;
}
.signup ul.cards {
  padding: 0px;
  margin-top: 10px;
  display: flex;
}
.signup ul.cards li {
  list-style: none;
  width: 40px;
  margin-right: 10px;
}
.signup .popover-block-container {
  height: 18px;
  outline: none;
}
.signup .popover-block-container button {
  border: none;
  background: transparent;
  outline: none;
  font-size: 16px;
}
.signup .popover-block-container button .fa {
  color: #ce2c4f;
  font-size: 16px;
  font-weight: 700;
}
.signup .mobvoucher {
  margin: 40px 0px 0px;
}
.signup .mobvoucher h3.n-head {
  font-family: "Montserrat";
  font-weight: bold;
  font-style: normal;
  color: #282d3d;
  font-size: 16px;
  line-height: 25px;
}
.signup .mobvoucher button img {
  width: 17px;
  margin-left: 5px;
  margin-top: 5px;
}
.signup .mobvoucher button:hover {
  background: rgba(40, 45, 61, 0.8);
}
.signup .mobvoucher p.error {
  font-size: 14px;
  font-style: italic;
  color: #ce2c4f;
  margin: 0px;
  line-height: normal;
}
.signup .mobbtn {
  display: none;
}
.signup .mobbtn button.certification {
  height: 50px;
  margin-bottom: 10px;
  outline: none;
}
.signup .mobbtn a {
  color: #333333;
  font-weight: 600;
  display: block;
  margin: 0px auto;
  text-align: center;
}
.signup .mobbtn a span {
  color: #6c213e;
}
.signup .mobtogtext p {
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
  color: #333333;
  font-weight: 600;
}
.signup .mobtogtext .togbutton {
  display: flex;
  width: 100%;
}
.signup .mobtogtext .togbutton > div {
  flex: 1;
  border: 1px solid #ce2c4f;
  height: 3px;
}
.signup .mobtogtext .togbutton > div:first-child {
  background: #ce2c4f;
}
.signup .mobtogtext .togbutton > div:last-child {
  background: transparent;
}
.signup .monthly.active span.amount {
  color: #fff;
}
.signup button.certification.mB50 {
  margin-bottom: 50px !important;
}
.signup .mobvoucher .fa.fa-question-circle {
  color: #ce2c4f;
  font-size: 16px;
  font-weight: 700;
}

/* Customize checkbox the label (the container) */
.signup {
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
.signup .containercheck {
  display: flex;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 13px;
  color: #333;
  font-weight: 600;
  margin-top: 30px;
}
.signup .containercheck span {
  color: #ce2c4f;
  font-size: 13px;
  font-weight: 600;
  padding: 0px 3px;
}
.signup .containercheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.signup .containercheck .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(138, 134, 134);
  border: 1px solid #282d3d;
}
.signup .containercheck:hover input ~ .checkmark {
  background-color: #fff;
}
.signup .containercheck input:checked ~ .checkmark {
  background-color: #282d3d;
}
.signup .containercheck .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.signup .containercheck input:checked ~ .checkmark:after {
  display: block;
}
.signup .containercheck .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .stick3 {
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 100;
  }
  .d-logo {
    display: flex;
  }
  .stick3 .header-top .d-logo img {
    width: auto;
    height: 40px;
    margin: 0px auto;
  }
  .bred-Headings div {
    max-width: 100%;
    flex: 100%;
  }
  .bred-Headings > div:first-child h4 {
    font-size: 18px;
    text-align: center;
  }
  .bred-Headings > div:last-child {
    display: none;
  }
  .signup .signupmobile {
    display: block;
  }
  .mobtopmrgn {
    margin-top: 130px;
  }
  .signup {
    background: #fff;
  }
  .rightInfo {
    display: none;
  }
  .signup .mobbtn {
    display: block !important;
  }
  .signup .box {
    padding: 15px 0px !important;
  }
  .signup .box .level-box {
    flex-direction: column;
  }
  .signup .box h2.header {
    color: #333333;
    font-size: 16px;
  }
  .signup .box .headerbtn {
    border: none;
    background: transparent;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .signup .signbox .headerbtn {
    padding: 0px;
    display: block;
    min-height: 30px;
    border-bottom: 1px solid #ddd !important;
    border-radius: 0px;
    padding-bottom: 10px;
  }
  .signup .monthly {
    height: auto;
    display: flex;
    align-items: center;
  }
  .signup .container-box {
    max-width: 71%;
  }
  .signup .checkmark-circle {
    top: 50%;
    transform: translate(0%, -50%);
  }
  .signup .box .padingl0 {
    padding: 0px;
  }
  .signup .box .padingr0 {
    padding: 0px;
  }
}
html,
body {
  overflow: auto;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.spinner-global {
  width: 100px;
  height: 100px;
  display: block;
  margin: 0px auto;
}

/*viewprofile page design starte here*/
.mat-dialog-container {
  max-width: 75% !important;
  width: 100% !important;
  background: #fff;
  margin: 0px auto;
  padding: 0px !important;
  max-height: 70vh !important;
  overflow-y: auto;
}
.mat-dialog-container > div * {
  padding: 0px !important;
}
.mat-dialog-container video {
  max-width: 100%;
  max-height: 280px;
}
.mat-dialog-container .description {
  padding: 15px;
  padding-left: 30px !important;
}
.mat-dialog-container .img-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 30px !important;
}
.mat-dialog-container .close {
  position: absolute;
  top: -20px;
  right: 0px;
  background: #363636;
  background: #c50880 !important;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 35px;
  font-size: 2.5rem;
  padding: 6px;
  width: 30px;
  opacity: 1;
  z-index: 10;
  outline: none;
  aspect-ratio: 1/1;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mat-dialog-container .close span {
  font-size: 18px;
  font-weight: 400;
}
.mat-dialog-container .container {
  padding: 0px;
}
.mat-dialog-container .container img {
  width: 100%;
}
.mat-dialog-container .container {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.mat-dialog-container .title {
  margin-bottom: 15px !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
.mat-dialog-container .card {
  font-size: 1em;
  overflow: hidden;
  padding: 5;
  border: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  box-shadow: none;
  margin-top: 20px;
}
.mat-dialog-container .card .my_class_description .description {
  max-width: 100%;
  flex: 100%;
}
.mat-dialog-container .card .card-title {
  color: #000;
  line-height: 27px;
}
.mat-dialog-container .card .card-block {
  border: 0px !important;
  padding: 0px !important;
}
.mat-dialog-container .card .card-body {
  border: 0px !important;
}
.mat-dialog-container .card .carousel-item {
  width: 100% !important;
  display: flex;
  align-items: center;
  height: auto !important;
}
.mat-dialog-container .card .card-footer {
  background: #c50880;
  background: #fff;
  max-width: 100%;
  width: 100%;
  margin: 0px !important;
  display: flex;
  align-items: center;
  border-top: 1px solid #c50880 !important;
}
.mat-dialog-container .card .card-footer > div {
  padding: 0px;
}
.mat-dialog-container .card .card-footer .cat-text {
  margin: 0px !important;
  flex: 1;
  justify-content: flex-end;
}
.mat-dialog-container .card .card-footer .ellipse {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mat-dialog-container .card .card-footer .ellipse i {
  color: #000;
  font-size: 23px;
}
.mat-dialog-container .card .card-footer .material-icons,
.mat-dialog-container .card .card-footer img {
  color: #fff !important;
  cursor: pointer;
  font-size: 30px;
  width: 30px !important;
  height: 30px !important;
  aspect-ratio: 1/1;
  font-size: 12px;
}
.mat-dialog-container .card .card-footer .card-title {
  margin: 0px 10px !important;
  color: #000 !important;
}
.mat-dialog-container .card .card-footer .badge {
  background: #84248d !important;
  background: transparent !important;
  white-space: unset !important;
  height: auto !important;
  text-align: left;
  border: 1px solid #84248d;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  color: #000;
}
.mat-dialog-container .social {
  margin-bottom: 10px;
}
.mat-dialog-container .social a i {
  color: #fff !important;
}
.mat-dialog-container .social ul {
  padding: 0px 1.25rem;
  margin: 0px;
  list-style: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
}
.mat-dialog-container .social ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 5px;
}
.mat-dialog-container .social ul li span {
  color: #000;
  color: #000;
  font-size: 10px;
  font-weight: 500;
}
.mat-dialog-container .social ul li span.count {
  color: #c50880;
  font-weight: bold;
  font-size: 12px;
}
.mat-dialog-container .btn-floating {
  position: relative;
  z-index: 1;
  padding: 0;
  margin: 0px 10px;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 47px;
  width: 35px;
  height: 47px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.mat-dialog-container .btn-fb {
  color: #fff;
  background-color: #3b5998;
}
.mat-dialog-container .btn-tw {
  color: #fff;
  background-color: #55acee;
}
.mat-dialog-container .btn-dribbble {
  color: #fff;
  background-color: #ec4a89;
}
.mat-dialog-container .btn-com {
  color: #fff;
  background-color: #82248d;
}

@media (max-width: 767px) {
  .mat-dialog-container {
    max-width: 100% !important;
  }
  .mat-dialog-container .description {
    order: 2;
  }
  .mat-dialog-container .img-section {
    order: 1;
  }
  .mat-dialog-container .card-footer {
    order: 3;
  }
  .mat-dialog-container .card-footer > div {
    padding: 0px;
  }
  .mat-dialog-container .card-footer .cat-text {
    margin: 0px !important;
    flex: 1;
    width: 100%;
    justify-content: flex-end;
  }
  .mat-dialog-container .close {
    position: absolute;
    top: -17px;
    right: 0px;
    background: #363636;
    background: #c50880 !important;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* max-height: 25px; */
    font-size: 2.5rem;
    padding: 0px;
    width: 25px;
    opacity: 1;
    z-index: 10;
    outline: none;
    aspect-ratio: 1/1;
  }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .mat-dialog-container {
    max-height: 70vh !important;
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 1000px) and (min-width: 940px) {
  .desktoponly {
    display: block !important;
  }
}
/*home page tile design card handeler with responsive common for all*/
.card-handler {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 250px;
  padding-bottom: 60px;
  width: 100%;
}
.card-handler .card-wrap {
  width: 400px;
  width: 23%;
  border: 0;
  margin-bottom: 40px;
  position: relative;
  margin: 1rem 1%;
  height: 460px;
}
.card-handler i {
  color: #fff !important;
}
.card-handler .image-wrap {
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  height: 160px;
  border: 5px solid #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.card-handler .image-wrap h1 {
  display: flex;
  align-items: center;
  margin: 0px;
  transition: all 0.5s linear;
}
.card-handler .image-wrap:hover h1 {
  font-size: 20px;
  transition: all 0.5s linear;
}
.card-handler .image-wrap .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.card-handler .image-wrap .play-btn i {
  font-size: 18px !important;
}
.card-handler img {
  width: 100%;
  display: block;
  transition: all 0.5s linear;
  max-height: 250px;
  height: 100%;
  object-fit: contain;
}
.card-handler .card-wrap:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 0.5s linear;
}
.card-handler .social {
  margin-bottom: 10px;
}
.card-handler .social ul {
  padding: 0px 1.25rem;
  padding: 0px 10px;
  margin: 0px;
  list-style: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}
.card-handler .social ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 5px;
}
.card-handler .social ul li span {
  color: #000;
  color: #000;
  font-size: 10px;
  font-weight: 500;
}
.card-handler .social ul li span.count {
  color: #c50880;
  font-weight: bold;
  font-size: 12px;
}
.card-handler .btn-floating {
  position: relative;
  z-index: 1;
  padding: 0;
  margin: 0px 10px;
  margin: 0px;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 47px;
  width: 35px;
  height: 47px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.card-handler .btn-fb {
  color: #fff;
  background-color: #3b5998;
}
.card-handler .btn-fb:hover .fas:before {
  color: red;
}
.card-handler .btn-tw {
  color: #fff;
  background-color: #55acee;
}
.card-handler .btn-dribbble {
  color: #fff;
  background-color: #ec4a89;
}
.card-handler .btn-dribbble:hover .fas:before {
  color: green;
}
.card-handler .btn-com {
  color: #fff;
  background-color: #82248d;
}
.card-handler .text-wrap {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  text-align: left;
  box-shadow: 0.25rem;
  margin: 0px 0px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  height: calc(100% - 160px);
  padding-bottom: 114px;
  padding-bottom: 94px;
}
.card-handler .text-wrap .user-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.card-handler .text-wrap .user-group img {
  margin-left: 20px !important;
}
.card-handler .text-wrap .user-group h4 {
  margin: 0px;
  padding-left: 10px;
}
.card-handler h1 {
  margin-bottom: 0.75rem;
  font-size: 24px;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 1.25rem;
  padding-bottom: 0px;
  padding-top: 0px;
  min-height: 64px;
  min-height: 50px;
  line-height: 25px;
  font-weight: 500;
  color: #000;
}
.card-handler h4 {
  padding: 0.25px 0px;
  margin-bottom: 0.75rem;
  color: #3f51b5;
  padding: 1.25rem;
  padding-bottom: 0px;
  padding-top: 0px;
}
.card-handler p {
  font-size: 0.9rem;
  font-size: 16px;
  font-weight: 400;
  color: #747373;
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.5;
  text-align: left;
  padding: 1.25rem;
  padding-bottom: 0px;
  padding-top: 0px;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px!important;
  white-space: normal;
  word-break: break-word;
  display: flex;
  align-items: center; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
}
.card-handler .button-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 10px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  background: #c50880;
  background: transparent;
  min-height: 48px !important;
  height: 100%;
}
.card-handler .button-wrap p {
  margin: 0px;
  color: #fff;
}
.card-handler .cat-name {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card-handler .cat-name span {
  background: transparent;
  margin-top: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin: 0px !important;
  font-size: 14px;
  font-weight: bold;
  max-height: 48px !important;
  height: 100% !important;
  display: block;
  white-space: unset;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #c50880;
  border: 1px solid #c50880;
}
.card-handler .days {
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: bolder;
}
.card-handler .days p {
  color: #fff;
  color: #c50880;
  font-size: 14px;
  padding: 0px;
  font-weight: bold;
}
.card-handler .bottom-fix {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 0px);
}
.card-handler .bottom-fix-new {
  margin-top: 10px;
  position: absolute;
  bottom: 75px;
  width: calc(100% - 0px);
}
.card-handler video {
  padding-bottom: 0px !important;
}
.card-handler video[poster] {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
}

.title {
  padding-top: 0px !important;
}
.title .title-wrap {
  margin-bottom: 10px !important;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .card-handler {
    padding: 0px 15px;
  }
  .card-handler .card-wrap {
    width: 31%;
  }
  .card-handler h1,
  .card-handler h4,
  .card-handler p,
  .card-handler .social ul,
  .card-handler .button-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
  .card-handler .days p {
    padding: 0px;
  }
}
@media (max-width: 767px) {
  .card-handler .card-wrap {
    width: 48%;
  }
}
@media (max-width: 460px) {
  .card-handler .card-wrap {
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .card-handler {
    padding: 72px 20px;
    padding-bottom: 60px;
  }
  .card-handler .card-wrap {
    width: 31%;
  }
  .card-handler h1,
  .card-handler h4,
  .card-handler p,
  .card-handler .social ul,
  .card-handler .button-wrap {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .card-handler .days p {
    padding: 0px;
  }
}
@media (max-width: 767px) {
  .card-handler .card-wrap {
    width: 48%;
  }
}
@media (max-width: 460px) {
  .card-handler .card-wrap {
    width: 100%;
  }
}
/*add arrow on popup*/
.addarw {
  position: relative;
}
.addarw .arrow-wrap {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 1000;
  max-width: 100%;
  width: 100%;
  height: 50px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
}
.addarw .arrow-wrap .prev-arrow {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c50880;
  border-radius: 50%;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.addarw .arrow-wrap .prev-arrow i {
  color: #fff;
  margin-top: 3px;
}
.addarw .arrow-wrap .next-arrow {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c50880;
  border-radius: 50%;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.addarw .arrow-wrap .next-arrow i {
  color: #fff;
  margin-top: 3px;
}

/* home tile design common for all ends here*/
/* dropdown*/
.mat-menu-below {
  width: 400px;
  margin-top: 45px;
  position: fixed;
  top: 53px !important;
  right: 38px;
}
.mat-menu-below button {
  outline: none;
}

@media (max-width: 1024px) {
  .mat-menu-below {
    right: 15px;
  }
}
@media (max-width: 767px) {
  .mat-menu-below {
    right: 15px;
    max-width: calc(100% - 30px) !important;
  }
}
@media (max-width: 460px) {
  .mat-menu-below {
    max-width: 100% !important;
    width: 100% !important;
    right: 0px !important;
    margin-top: 32px !important;
    margin-top: 23px !important;
  }
}
/*createpost popup design*/
.creatpopupmodal {
  width: 40%;
  background: transparent;
  position: relative !important;
}
.creatpopupmodal h2 {
  color: #000;
  line-height: 27px;
}
.creatpopupmodal img {
  padding: 0px !important;
  height: 70px !important;
}
.creatpopupmodal .mat-dialog-container {
  max-width: 100% !important;
  padding: 0px 50px !important;
}
.creatpopupmodal .mat-dialog-container > div {
  padding: 0px !important;
}
.creatpopupmodal button {
  position: absolute;
  top: 6px;
  right: 10px;
  background: #363636;
  background: #c50880 !important;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 35px;
  font-size: 2.5rem;
  padding: 6px;
  width: 35px;
  opacity: 1;
  z-index: 10;
  outline: none;
  aspect-ratio: 1/1;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  border: 0px;
}
.creatpopupmodal button span {
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .creatpopupmodal {
    width: 100%;
    display: none;
  }
}
/* menu animation*/
.mat-menu-panel {
  border-radius: 0px !important;
}

.mat-menu-content {
  padding: 0px !important;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: transparent !important;
}

.mat-menu-below button {
  position: relative;
  margin-bottom: 5px;
  line-height: 38px;
  height: 38px;
  color: #000;
  font-weight: 500;
}

.mat-menu-below button i {
  margin-right: 10px;
}

.mat-menu-below button:last-child {
  margin: 0px;
}

.mat-menu-below button:before {
  content: "";
  width: 0px;
  height: 100%;
  top: 0;
  left: 0;
  background: #c50880;
  position: absolute;
  transition: width 0.5s ease-in-out;
  z-index: -1;
}

.mat-menu-below button:hover {
  color: #fff;
}

.mat-menu-below button:hover:before {
  width: 100%;
}

/*spinner*/
.img-div-left {
  position: fixed;
  bottom: -6px;
  z-index: 10000;
  width: 200px;
  height: 200px;
  margin: 0px auto;
  width: 100%;
}

@media (max-width: 767px) {
  .img-div-left {
    height: auto;
  }
}
/* most view section starts here*/
.most-view {
  padding: 0px;
}
.most-view .owl-stage-outer {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.most-view .owl-stage-outer .owl-stage {
  max-width: 100% !important;
  display: flex;
  overflow: hidden;
}
.most-view .owl-stage-outer .owl-stage .owl-item {
  width: 23% !important;
  margin: 1rem 0.5% !important;
  min-width: 305px !important;
}
.most-view .owl-stage-outer .owl-stage .owl-item .card-handler {
  padding-bottom: 0px !important;
  margin-left: 2px;
}
.most-view .owl-stage-outer .owl-stage .owl-item .card-handler .card-wrap {
  width: 100% !important;
  min-width: 305px !important;
  height: 450px;
  margin: 0px !important;
}
.most-view .owl-nav {
  position: absolute;
  width: calc(100% + 56px);
  top: 50%;
  left: -28px;
  transform: translate(0%, -50%);
  right: -28px;
}
.most-view .owl-nav .owl-prev {
  background: #c50880 !important;
  left: 0px;
  position: absolute;
  border-radius: 50% !important;
  aspect-ratio: 1/1;
  width: 35px;
  height: 35px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.most-view .owl-nav .owl-prev:before {
  content: "\f104";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  background: transparent;
}
.most-view .owl-nav .owl-next {
  right: 0px;
  position: absolute;
  background: #c50880 !important;
  border-radius: 50% !important;
  aspect-ratio: 1/1;
  width: 35px;
  height: 35px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.most-view .owl-nav .owl-next:after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  background: transparent;
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .most-view {
    padding: 0px 25px;
  }
  .most-view .owl-stage-outer .owl-stage .owl-item {
    width: 31% !important;
    min-width: 15% !important;
  }
  .most-view .owl-stage-outer .owl-stage .owl-item .card-handler {
    padding: 0px;
  }
  .most-view .owl-stage-outer .owl-stage .owl-item .card-handler .card-wrap {
    min-width: unset !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 992px) {
  .most-view {
    padding: 0px 25px;
  }
  .most-view .owl-stage-outer .owl-stage .owl-item {
    width: 31% !important;
    min-width: 15.2% !important;
  }
  .most-view .owl-stage-outer .owl-stage .owl-item .card-handler {
    padding: 0px;
  }
  .most-view .owl-stage-outer .owl-stage .owl-item .card-handler .card-wrap {
    min-width: unset !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .most-view {
    padding: 0px 20px;
  }
  .most-view .owl-nav {
    left: -20px;
    right: -20px;
    width: calc(100% + 40px);
  }
  .most-view .owl-nav .owl-next {
    width: 25px;
    height: 25px;
  }
  .most-view .owl-nav .owl-prev {
    width: 25px;
    height: 25px;
  }
}
.mat-hint {
  font-weight: bold;
}

.cdk-overlay-connected-position-bounding-box .mat-menu-content {
  height: 100vh;
}

/* register page */
.chkbx {
  padding: 0px 20px;
}
.chkbx .mat-checkbox-inner-container {
  margin: 0px;
}
.chkbx .mat-checkbox-label {
  position: relative;
  top: -3px;
  white-space: break-spaces;
  padding-left: 10px;
}

/*tile auto height mobile*/
.ellipse-wrap {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
}

@media (max-width: 767px) {
  .right-panel {
    padding: 0px 15px !important;
  }
}
@media (min-width: 993px) {
  .user-group.usergroupdesktop {
    display: flex;
  }
  .user-group.usergroupmobile {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .user-group.usergroupdesktop {
    display: none !important;
  }
  .user-group.usergroupmobile {
    display: flex !important;
  }
}
@media (max-width: 767px) {
  .mat-dialog-container .addarw .description {
    padding: 0px !important;
  }
  .mat-dialog-container .addarw .description .card-block {
    padding: 10px 30px !important;
  }
  .mat-dialog-container .addarw .social ul {
    justify-content: space-around !important;
  }
}
/* category slider design starts here*/
.category-slider {
  background: #c50880;
  padding: 0px;
  padding-top: 10px;
  margin-bottom: 30px;
}
.category-slider h2 {
  color: #fff;
  padding: 10px;
  margin-bottom: 0px;
}
.category-slider .image-wrap {
  background: #fff;
  min-height: 100px;
}
.category-slider .image-wrap h1 {
  padding: 0px !important;
}
.category-slider .card-handler .image-wrap {
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.category-slider .card-handler .image-wrap h1 {
  min-height: auto;
}
.category-slider .card-handler .image-wrap:hover h1 {
  font-size: 18px !important;
  transition: none;
  padding: 0px !important;
}
.category-slider .owl-stage-outer .owl-stage .owl-item .card-handler .card-wrap {
  min-width: auto !important;
  width: 100% !important;
}
.category-slider .card-handler .card-wrap {
  height: auto !important;
}
.category-slider .owl-nav .owl-prev {
  top: -28px !important;
}
.category-slider .owl-nav {
  position: absolute;
  width: calc(100% + 100px);
  top: 50%;
  left: -50px;
  transform: translate(0%, -50%);
  right: -50px;
  display: flex;
  align-items: center;
  margin-top: 0px;
  display: none !important;
}

.mat-form-field {
  max-width: 90%;
  margin: 0px auto;
  display: flex !important;
  flex-direction: column;
  height: 50px;
  width: 90% !important;
  margin: 10px auto !important;
  padding: 0px !important;
  border-radius: 10px !important;
}
.mat-form-field .mat-form-field-flex {
  border: 1px solid lightgray;
  background: #fff;
  border-radius: 5px !important;
}
.mat-form-field .mat-form-field-flex:hover {
  outline: 0px !important;
}
.mat-form-field .mat-form-field-flex mat-select label {
  position: absolute;
  top: 30px;
  color: #000 !important;
}

.cdk-overlay-container {
  z-index: 1000000;
}

.snackBarpanelClass {
  position: fixed;
  top: 0px !important;
  left: 0px !important;
  max-width: 100vw !important;
  width: 100vw !important;
  max-height: 100vh !important;
  height: 100vh !important;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  margin: 0px !important;
}
.snackBarpanelClass .mat-simple-snackbar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-width: 40vw !important;
  width: 100% !important;
  padding: 20px 20px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: left;
  z-index: 3;
  color: #000;
  font-weight: bold;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  text-align: center;
  border-radius: 10px;
}
.snackBarpanelClass .mat-simple-snackbar span {
  width: 100%;
  display: block;
  margin: 0px auto;
  text-align: center;
}

.snackBarpanelClassError {
  position: fixed;
  top: 0px !important;
  left: 0px !important;
  max-width: 100vw !important;
  width: 100vw !important;
  max-height: 100vh !important;
  height: 100vh !important;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  margin: 0px !important;
}
.snackBarpanelClassError .mat-simple-snackbar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-width: 40vw !important;
  width: 100% !important;
  padding: 20px 20px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: left;
  z-index: 3;
  color: #000;
  font-weight: bold;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  text-align: center;
  border-radius: 10px;
}
.snackBarpanelClassError .mat-simple-snackbar span {
  width: 100%;
  display: block;
  margin: 0px auto;
  text-align: center;
}

/*new design starts here*/
.banner-wrap .owl-nav {
  display: flex !important;
  width: 100%;
  position: relative;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.banner-wrap .owl-nav .owl-prev {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.5) !important;
  position: absolute;
  left: 50px;
  border-radius: 50% !important;
}
.banner-wrap .owl-nav .owl-prev span {
  font-size: 40px;
}
.banner-wrap .owl-nav .owl-next {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.5) !important;
  position: absolute;
  right: 50px;
  border-radius: 50% !important;
}
.banner-wrap .owl-nav .owl-next span {
  font-size: 40px;
}

/*new tile design*/
.newdesigntile .card-handler .card-wrap {
  height: auto !important;
  box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  overflow: hidden;
}
.newdesigntile .card-handler .card-wrap:hover:after {
  content: "";
  width: 100%;
  height: 10px;
  background: #bf51d6;
  position: absolute;
  top: 0px;
  left: 0px;
}
.newdesigntile .card-handler .card-wrap .image-wrap {
  border: 0px !important;
  position: relative;
  border-radius: 0px;
  box-shadow: none;
}
.newdesigntile .card-handler .card-wrap .image-wrap .cat-name {
  position: absolute;
  bottom: 0px;
  right: 0px;
  border: 0px;
  border-radius: 0px;
  box-shadow: none !important;
  padding: 5px 20px 5px 35px;
}
.newdesigntile .card-handler .card-wrap .image-wrap .cat-name:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0px;
  right: -15px;
  background: #3e0368;
  transform: skewX(-25deg);
  z-index: 1;
  border-radius: 10px 0px 0px 0px;
}
.newdesigntile .card-handler .card-wrap .image-wrap .cat-name span {
  border: none !important;
  color: #fff;
  border-radius: 0px !important;
  position: relative;
  z-index: 2;
  font-size: 14px;
  font-weight: 500;
}
.newdesigntile .card-handler .card-wrap .text-wrap {
  padding-bottom: 100px;
  box-shadow: none;
  position: relative;
}
.newdesigntile .card-handler .card-wrap .text-wrap h1 {
  font-size: 18px;
  font-weight: 500;
  min-height: unset;
  margin-bottom: 5px;
  line-height: 25px;
  min-height: 50px;
}
.newdesigntile .card-handler .card-wrap .text-wrap h4 {
  color: #000 !important;
  font-size: 12px !important;
}
.newdesigntile .card-handler .card-wrap .text-wrap p {
  font-size: 16px;
  line-height: 25px;
  padding: 0px 20px;
  line-height: 15px !important;
  text-align: center;
  text-align: left;
  line-height: 20px !important;
}
.newdesigntile .card-handler .card-wrap .text-wrap .days {
  padding-left: 0px;
}
.newdesigntile .card-handler .card-wrap .text-wrap .days p {
  padding: 0px;
  color: #000;
  margin: 0px;
}
.newdesigntile .card-handler .card-wrap .text-wrap .days p span {
  font-size: 12px;
}
.newdesigntile .card-handler .card-wrap .text-wrap .days p span i {
  color: #000 !important;
  margin-right: 5px;
}
.newdesigntile .card-handler .card-wrap .text-wrap .title-wrap {
  display: flex;
  padding: 0px 20px;
}
.newdesigntile .card-handler .card-wrap .text-wrap .title-wrap h4 {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
}
.newdesigntile .card-handler .card-wrap .text-wrap .title-wrap .days p {
  font-size: 12px;
  color: #000;
  font-weight: 500;
  margin: 0px;
}
.newdesigntile .card-handler .card-wrap .text-wrap .title-wrap .days p i {
  color: #000 !important;
  margin-right: 5px;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .button-wrap {
  display: none;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social {
  display: flex;
  margin-bottom: 13px;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social ul {
  justify-content: left;
  padding: 0px 0px 0px 20px;
  width: auto;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social ul li {
  margin-right: 10px;
  border-radius: 50%;
  margin-left: 0px;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social ul li a {
  margin: 0px;
  background: none;
  box-shadow: none;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social ul li a i {
  color: #000 !important;
  font-size: 13px;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social ul li:first-child {
  border: 1px solid #3b5998;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social ul li:nth-child(2) {
  border: 1px solid #55acee;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social ul li:nth-child(3) {
  border: 1px solid #ec4a89;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social ul li:last-child {
  border: 1px solid #82248d;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social div {
  flex: 1;
  font-size: 12px;
  padding: 0px 20px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social div a {
  color: #000;
  text-decoration: underline;
  font-weight: 500;
  position: relative;
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social div a:after {
  content: "›";
  position: absolute;
  font-size: 20px;
  right: 0px;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social div a:hover {
  text-decoration: none;
}
.newdesigntile .owl-stage-outer .owl-stage .owl-item .card-handler {
  height: 100%;
}
.newdesigntile .owl-stage-outer .owl-stage .owl-item .card-handler .card-wrap {
  height: auto !important;
}

.newdesigntile .card-handler .card-wrap .text-wrap .countdetails-page p {
  line-height: 15px !important;
}

.countdetails-page {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0px 20px;
}
.countdetails-page p {
  text-align: center !important;
}
.countdetails-page > div {
  display: flex;
  height: 100%;
  padding: 0px;
}
.countdetails-page > div:last-child {
  display: flex;
  justify-content: end;
}
.countdetails-page > div:last-child p {
  padding: 0px;
}
.countdetails-page ul {
  display: flex;
  margin: 0px;
  padding: 0px;
  list-style: none;
  position: relative;
  flex: 1;
  height: 100%;
  align-items: center;
}
.countdetails-page ul li {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 5px;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-self: center;
}
.countdetails-page ul li i {
  font-size: 7px;
}
.countdetails-page ul li:first-child {
  background: #c4057f;
  background: #3b5998;
  left: 0px;
}
.countdetails-page ul li:nth-child(2) {
  background: #eb445a;
  background: #ec4a89;
  left: 16px;
}
.countdetails-page ul li:last-child {
  background: #8e1f8a;
  background: #55acee;
  left: 30px;
}
.countdetails-page ul li:last-child i {
  margin-left: 2px;
}
.countdetails-page ul + p {
  flex: 1;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 0px;
  font-size: 12px;
}
.countdetails-page ul + p:after {
  display: none;
}
.countdetails-page p {
  font-weight: 500;
  text-align: left;
  margin: 0px;
  padding: 0px 5px;
  display: inline-block;
  position: relative;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.countdetails-page p:last-child:after {
  content: "-";
  position: absolute;
  left: 0px;
  width: 10px;
  display: none;
}

.homeTip .card-handler {
  padding-bottom: 0px !important;
}

.cate-badge {
  width: 100% !important;
}
.cate-badge .mat-tab-group .mat-tab-header {
  border: 0px !important;
}
.cate-badge .mat-tab-group .mat-tab-list .mat-ink-bar {
  display: none;
}
.cate-badge .mat-tab-group .mat-tab-label-container {
  padding-bottom: 40px;
  padding-bottom: 0px;
  overflow: auto !important;
  overflow-y: hidden !important;
}
.cate-badge .mat-tab-group .mat-tab-label-container .mat-tab-list {
  transform: none !important;
}
.cate-badge .mat-tab-group .mat-tab-labels .mat-tab-label {
  padding: 0px !important;
  margin-right: 30px;
  margin-right: 20px !important;
  margin-right: 0px !important;
  min-width: unset;
  padding: 5px 20px !important;
}
.cate-badge .mat-tab-group .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  padding: 0px;
  background: #bf51d6;
  border-radius: 50px;
  margin-right: 30px;
  opacity: 1 !important;
  overflow: inherit;
  box-shadow: none !important;
}
.cate-badge .mat-tab-group .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
  overflow: inherit;
  color: #fff;
}
.cate-badge .mat-tab-group .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-tab-label-content:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #bf51d6;
  position: absolute;
  top: 100%;
  z-index: 10;
}

/*banner design starts here for common*/
.inner-banner {
  top: -84px;
}

/* home page responsive*/
.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none !important;
}

.cate-badge .mat-tab-group .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  margin-right: 0px !important;
}

.cate-badge .mat-tab-group .mat-tab-labels .mat-tab-label.mat-tab-label {
  margin-right: 0px !important;
}

@media (max-width: 767px) {
  .header-position {
    margin: 0px 15px !important;
    width: calc(100% - 30px) !important;
  }
  .header-position .top-bar {
    display: none !important;
  }
  .header-position header {
    width: 100% !important;
    margin: 1rem 0px 1rem !important;
    height: 52px !important;
  }
  .mat-menu-panel {
    position: fixed;
    top: 10px !important;
    margin: 0px 15px;
    width: calc(100% - 20px) !important;
    border-radius: 15px !important;
    background: white;
    max-height: calc(100vh - 20px) !important;
    margin: 0px !important;
    left: 10px;
    right: 10px;
  }
  .mat-menu-panel .mat-menu-content {
    padding: 10px 0px !important;
    height: calc(100vh - 20px) !important;
  }
  .mat-menu-panel .mat-menu-content button i {
    display: none;
  }
  .mat-menu-panel .mat-menu-content .bottm-link {
    border-top: 1px solid #ddd;
    padding-top: 17px;
  }
  .mat-menu-panel .mat-menu-content .bottm-link ul {
    padding: 0px 16px;
    margin: 0px;
    list-style: none;
  }
  .mat-menu-panel .mat-menu-content .bottm-link ul a {
    height: 35px;
    display: block;
    color: #000;
    font-weight: 500;
    font-size: 14px;
  }
  .mat-menu-panel .mat-menu-content .close {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 1;
  }
  .mat-menu-panel .mat-menu-content .close i {
    color: #bf51d6;
  }
  .mat-menu-panel .mobilelogoadd img {
    width: 100px;
    margin-left: 15px;
  }
  .main-banner:after {
    position: fixed !important;
  }
  .banner-wrap .owl-nav .owl-prev {
    left: 15px;
    display: none !important;
  }
  .banner-wrap .owl-nav .owl-next {
    right: 15px;
    display: none !important;
  }
  .banner-row .banner-text {
    max-width: 700px;
    width: calc(100% - 30px) !important;
    margin: 10vh auto 0px !important;
  }
  .banner-row .banner-text h1 {
    font-size: 28px !important;
  }
  .search-wrap {
    margin-top: -64vh !important;
    padding-bottom: 60px !important;
  }
  .search_icon {
    position: absolute !important;
    height: 46px !important;
    width: 28% !important;
    border-radius: 50px !important;
    background: #bf51d6 !important;
  }
  .count-wrap {
    max-width: 100% !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  .count-wrap div {
    max-width: 44% !important;
    width: 100% !important;
    flex: none !important;
  }
  .count-wrap div p {
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  .newdesigntile .card-handler {
    padding: 0px 0px;
  }
  .whitebg-new {
    margin: 0px 15px !important;
    margin-bottom: 40px !important;
  }
  .whitebg-new .title-wrap {
    font-size: 20px !important;
    border-bottom: 0px !important;
  }
  .whitebg-new .title-wrap:after {
    display: none !important;
  }
  .whitebg-new .newdesigntile .card-handler .card-wrap {
    background: #fff !important;
  }
  .whitebg-new .newdesigntile .card-handler .card-wrap .user-group {
    padding: 0px 10px;
  }
  .whitebg-new .newdesigntile .card-handler .card-wrap .text-wrap h1,
  .whitebg-new .newdesigntile .card-handler .card-wrap .text-wrap p,
  .whitebg-new .newdesigntile .card-handler .card-wrap .text-wrap .countdetails-page {
    padding: 0px 10px !important;
  }
  .whitebg-new .newdesigntile .card-handler .card-wrap .text-wrap h1 p,
  .whitebg-new .newdesigntile .card-handler .card-wrap .text-wrap p p,
  .whitebg-new .newdesigntile .card-handler .card-wrap .text-wrap .countdetails-page p {
    padding: 0px !important;
  }
  .whitebg-new .newdesigntile .card-handler .card-wrap .social ul {
    padding-left: 10px !important;
  }
  .whitebg-new .newdesigntile .bottom-fix {
    position: absolute !important;
  }
  footer .container1 {
    margin: 0px 15px !important;
  }
  footer .f-links ul {
    margin-left: -15px !important;
    display: block !important;
  }
  footer .f-links ul li a {
    padding: 0px 15px;
    display: block;
    height: 35px;
    line-height: 30px;
  }
  footer .signup-btn {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }
  footer .signup-btn .btn-create-post {
    padding: 10px 15px !important;
    margin-left: 0px !important;
    margin-bottom: 10px;
    width: 60%;
  }
  footer .social-link {
    justify-content: flex-start !important;
  }
  footer .container1 .copy-rights {
    padding-top: 0px !important;
    margin-top: 0px !important;
    height: auto !important;
  }
  footer .container1 .copy-rights div:first-child {
    order: 2;
    position: relative;
    z-index: 2;
  }
  footer .container1 .copy-rights div:last-child {
    order: 1;
  }
  footer .cpyrgt {
    display: block !important;
    width: 100%;
    order: 2;
    position: relative;
    top: 14px;
    padding-bottom: 10px;
  }
  footer .cpyrgt li {
    padding: 0px 0px !important;
  }
  footer .cpyrgt li a {
    padding: 0px 0px;
    display: block;
    height: 35px;
    line-height: 30px;
  }
  footer:after {
    bottom: 0px !important;
    height: 70px !important;
    z-index: 1 !important;
  }
  .cat-parent:after {
    content: "";
    width: 170% !important;
    height: 21% !important;
    -webkit-clip-path: ellipse(25% 40% at 19% 23%);
    clip-path: ellipse(30% 76% at 19% 23%);
    background: #653586;
    position: absolute;
    top: -7% !important;
    left: -19% !important;
    z-index: -1;
    transform: rotate(-24deg);
  }
  .cat-parent .cat-wrap {
    padding: 40px 20px !important;
  }
  .cat-parent .cat-tile-wrap {
    justify-content: space-between !important;
  }
  .cat-parent .cat-tile-wrap .cat-tile {
    width: 47% !important;
    margin: 15px 0px !important;
    padding: 10px 15px;
  }
  .twocol-tip {
    padding: 40px 20px !important;
  }
  .twocol-tip .btn {
    justify-content: center !important;
  }
  .twocol-tip img {
    margin-bottom: 30px !important;
  }
  .twocol-tile {
    padding: 40px 20px !important;
  }
  .twocol-tile .twocol-tilewrap {
    padding: 0px !important;
  }
  .twocol-tile .twocol-tilewrap .btn {
    justify-content: center !important;
  }
  .mat-tab-group {
    flex-direction: unset;
  }
  .mat-tab-list {
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  .mat-tab-list::-webkit-scrollbar {
    display: none;
  }
  .mat-tab-labels {
    display: flex;
    padding: 0px 30px 0px 0px;
    width: auto;
    margin-top: 10px;
    width: max-content !important;
    margin: 0px auto !important;
  }
  .cate-badge .mat-tab-group .mat-tab-labels .mat-tab-label {
    min-width: 150px !important;
    min-width: max-content !important;
  }
  .cate-badge .mat-tab-group .mat-tab-labels .mat-tab-label.mat-tab-label-active {
    margin-right: 20px !important;
  }
  .cate-badge .mat-tab-group .mat-tab-labels .mat-tab-label.mat-tab-label {
    margin-right: 20px !important;
    margin-right: 0px !important;
  }
}
@media only screen and (min-width: 460px) and (max-width: 767px) {
  .banner-wrap {
    height: 170vh !important;
  }
  .banner-wrap * {
    height: 170vh !important;
  }
  .search-wrap {
    margin-top: -112vh !important;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 992px) and (orientation: portrait) {
  .mat-menu-panel {
    position: fixed;
    top: 10px !important;
    margin: 0px 15px;
    width: calc(100% - 20px) !important;
    max-width: calc(100% - 20px) !important;
    border-radius: 15px !important;
    background: white;
    max-height: calc(100vh - 20px) !important;
    margin: 0px !important;
    left: 10px;
    right: 10px;
  }
  .mat-menu-panel .mat-menu-content {
    padding: 10px 0px !important;
    height: calc(100vh - 20px) !important;
    border-bottom: 1px solid #ddd;
  }
  .mat-menu-panel .mat-menu-content button i {
    display: none;
  }
  .mat-menu-panel .mat-menu-content .bottm-link {
    border-top: 1px solid #ddd;
    padding-top: 17px;
  }
  .mat-menu-panel .mat-menu-content .bottm-link ul {
    padding: 0px 16px;
    margin: 0px;
    list-style: none;
  }
  .mat-menu-panel .mat-menu-content .bottm-link ul a {
    height: 35px;
    display: block;
    color: #000;
    font-weight: 500;
    font-size: 14px;
  }
  .mat-menu-panel .mat-menu-content .close {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 1;
  }
  .mat-menu-panel .mat-menu-content .close i {
    color: #bf51d6;
  }
  .mat-menu-panel .mobilelogoadd img {
    width: 100px;
    margin-left: 15px;
  }
  .card-handler .text-wrap .user-group {
    padding: 0px 10px;
  }
  .card-handler .card-wrap {
    width: 48% !important;
  }
  .cdk-overlay-connected-position-bounding-box .mat-menu-content {
    height: auto !important;
  }
  .linkbtn-wrap {
    display: none !important;
  }
  .banner-row .banner-text {
    margin-top: -38% !important;
  }
  .search-wrap {
    max-width: 90% !important;
    margin-top: -41vh !important;
  }
  .count-wrap {
    max-width: 60% !important;
  }
  .img-slide {
    height: 70vh !important;
  }
  .main-banner {
    height: 70vh !important;
  }
  .banner-wrap {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100vw;
    height: 70vh !important;
    margin: 0px;
    padding: 0px;
  }
  .banner-wrap > * {
    height: 100% !important;
  }
  .cate-badge .mat-tab-group .mat-tab-labels {
    justify-content: center !important;
  }
  .cat-parent:after {
    content: "";
    width: 170% !important;
    height: 21% !important;
    -webkit-clip-path: ellipse(25% 40% at 19% 23%);
    clip-path: ellipse(30% 76% at 19% 23%);
    background: #653586;
    position: absolute;
    top: -7% !important;
    left: -19% !important;
    z-index: -1;
    transform: rotate(-24deg);
  }
  .cat-parent .cat-wrap {
    padding: 40px 20px !important;
  }
  .cat-parent .cat-tile-wrap {
    justify-content: left !important;
  }
  .cat-parent .cat-tile-wrap .cat-tile {
    width: 29% !important;
    margin: 15px 0px !important;
    padding: 10px 15px;
    margin: 15px !important;
  }
}
@media screen and (min-device-width: 992px) and (max-device-width: 1180px) {
  .card-handler .card-wrap {
    width: 31% !important;
  }
  .cate-badge .mat-tab-group .mat-tab-labels {
    justify-content: center !important;
  }
}
/*common banner design starts here*/
.common-banner {
  width: 100%;
  position: relative;
}
.common-banner .common-text {
  position: absolute;
  width: 43%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  text-align: center;
}
.common-banner .common-text h2 {
  color: #fff;
}
.common-banner img {
  width: 100%;
  max-height: 550px !important;
  height: 100% !important;
}
.common-banner + .common-overlap {
  position: relative;
  margin-top: -25% !important;
  z-index: 14;
  max-width: 600px;
  margin: 0px auto 45px;
  text-align: center;
}
.common-banner + .common-overlap + section {
  margin: 0px 50px;
  width: calc(100% - 100px);
  position: relative;
  left: 0px;
  background: #fff;
  border-radius: 10px !important;
  overflow: hidden;
}

.common-wrap {
  background: #fff;
  padding: 0px 40px;
  border-radius: 10px;
  margin: 0px 50px;
}

.candidate-btn {
  display: none !important;
}

.mobileonly + .common-overlap + section {
  padding-top: 40px;
}

.newdeshboreddesign {
  margin-top: 100px;
  padding: 0px 15px;
}
.newdeshboreddesign .user-group.usergroupmobile {
  padding: 0px 10px !important;
}
.newdeshboreddesign .user-group.usergroupmobile + p {
  padding: 0px 10px !important;
}
.newdeshboreddesign .bottom-fix .social ul {
  padding-left: 10px !important;
}
.newdeshboreddesign .bottom-fix .social div {
  padding-right: 10px !important;
}

/*common banner mobile design starts here*/
@media (max-width: 992px) {
  .common-banner + .common-overlap {
    margin: 0px auto 40px;
  }
  .common-banner + .common-overlap .common-text {
    padding: 0px 15px;
  }
  .common-banner + .common-overlap + section {
    margin: 0px 15px;
    width: calc(100% - 30px);
    padding-top: 40px !important;
  }
  .common-banner {
    height: 400px;
    height: 310px;
  }
  .common-banner img {
    object-fit: cover;
  }
  .mobileonly + .common-overlap + section {
    padding-top: 40px;
  }
  .mobileonly + .common-overlap + section .card-handler {
    padding: 0px !important;
  }
  .mobileonly + .common-overlap + section .user-group.usergroupmobile {
    padding: 0px 10px;
  }
  .mobileonly + .common-overlap + section .countdetails-page {
    padding: 0px 10px;
  }
  .mobileonly + .common-overlap + section .social ul {
    padding-left: 10px !important;
  }
  .mobileonly + .common-overlap + section .social div {
    padding-right: 10px !important;
  }
  .combo-icon + p {
    padding: 0px !important;
    margin-left: 12px !important;
  }
  .combo-icon li:nth-child(2) {
    left: 13px !important;
  }
  .combo-icon li:last-child {
    left: 25px !important;
  }
  .header-position.fixed {
    padding: 0px !important;
    margin: 0px !important;
    width: 100% !important;
  }
  .header-position.fixed header {
    margin: 1rem 0px 0px !important;
  }
}
.fab-buttonwrapper {
  display: none !important;
}

.newdesigntile .card-handler .card-wrap .bottom-fix .social ul li a i.savedColorClass {
  color: green !important;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social ul li a i.likeColorClass {
  color: red !important;
}
.newdesigntile .card-handler .card-wrap .bottom-fix .social ul li a .button-wrap .days p span i {
  color: #c50880 !important;
  padding-right: 2px;
}

.contactpage .mat-form-field {
  margin: 0px !important;
  max-width: 100% !important;
  width: 100% !important;
}
.contactpage .mat-form-field .mat-form-field-underline::before {
  display: none !important;
}

.hyperlink {
  color: #bf51d6 !important;
  text-decoration: underline;
  font-weight: 400 !important;
}
.hyperlink:hover {
  text-decoration: none !important;
}

/*tablet design for all pages starts here*/
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .banner-wrap .owl-nav {
    top: 40% !important;
  }
}
@media (max-width: 992px) {
  .newdesign-tip .container .card-handler {
    padding-top: 0px !important;
  }
  .video-parent-scroll .arrow-wrap {
    right: 16% !important;
  }
  .twocol-tile {
    padding-top: 0px !important;
  }
  .twocol-tile .image img {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .common-banner + .common-overlap {
    margin-top: -56% !important;
  }
  .newdesign-tip .container .container .card-handler .card-wrap {
    width: 100% !important;
  }
  .video-parent-scroll .arrow-wrap {
    right: 0% !important;
  }
}
.left-panel .mdc-list-item__primary-text {
  color: #fff !important;
}

@media (max-width: 767px) {
  .menuMobile {
    max-width: 100vw !important;
    width: 100vw !important;
    left: 1px !important;
    right: 0px;
    margin: 0px auto;
    position: fixed !important;
    top: 70px !important;
  }
}
.dia-box {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  text-shadow: 0px 0px 3px #ddd;
  font-weight: 600;
  background-image: url("assets/img/favicon_io/32x32.png");
  line-height: 30px;
}

.newmenumobiledesign .menu-btn {
  border-bottom: 1px solid #3e0368;
}
.newmenumobiledesign .menu-btn span {
  color: #3e0368;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.newmenumobiledesign .menu-btn span i {
  width: 25px !important;
}
.newmenumobiledesign .menu-btn:nth-child(3) span.mdc-list-item__primary-text {
  margin-left: -8px;
}
.newmenumobiledesign .menu-btn:nth-child(3) i {
  position: relative;
  left: 8px !important;
}
.newmenumobiledesign .bottm-link {
  padding: 5px 18px;
}
.newmenumobiledesign svg {
  fill: #3e0368;
  width: 33px;
  height: 20px;
}

.cdk-overlay-pane {
  width: 86%;
  left: 0px;
}

.mobile-u-name {
  background: #3e0368 !important;
}

.anisecondheart {
  position: absolute;
  left: 24px;
  right: 84%;
  bottom: 15px;
  width: 14%;
  z-index: 3;
  background: transparent;
}
.anisecondheart i {
  color: red !important;
}
.anisecondheart.viewtipanimation {
  left: 15px !important;
}
.anisecondheart.reeltipanimation {
  top: 182px !important;
  bottom: auto !important;
  height: auto !important;
  left: 17px !important;
}

.bubbling-heart div {
  top: 48%;
  left: -13%;
  transform: translate(-20%, 7vh) scale(1);
  transform: none;
  opacity: 0;
  animation: bubbleUp 1.5s infinite;
  animation-iteration-count: 1;
}

.bubbling-heart div .fa {
  color: red !important;
  font-size: 30px;
}

.bubbling-heart div {
  animation-delay: 0.5s;
}

.bubbling-heart div {
  animation-delay: 0.5s;
}

@keyframes bubbleUp {
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0%, -30vh) scale(2);
  }
}
@keyframes oscillate {
  50% {
    transform: translateX(-12.5vw) rotate(-25deg);
  }
}